import { useState, useEffect } from "react"
import { OutlinedInput } from "@mui/material"
import { FIELD_TYPES } from "@ais/constants"
import {
    getTrialBalanceAmountEnding,
    isValueTrialBalanceValue, renderLabel
} from "@ais/forms";

export const SHORT_ANSWER_MAX_CHARACTERS = 256
export const LONG_ANSWER_MAX_CHARACTERS = 4096

export const NoValidationInput = (props) => {
    const [inputValue, setInputValue] = useState("")
    const { field, disabled, defaultValue, onChange, onBlur, onFocus } = props
    const [cursor, setCursor] = useState(null);
    const [element, setElement] = useState(null);

    useEffect(() => {
        const isTrialBalanceValue = isValueTrialBalanceValue(defaultValue);
        if (isTrialBalanceValue) {
            const tbAmountEnding = getTrialBalanceAmountEnding(defaultValue);

            setInputValue(tbAmountEnding);
        } else
            setInputValue(defaultValue);
    }, [defaultValue])

    const handleChange = (e) => {
        if (onChange) {
            const pointer = e.target.selectionStart;
            const element = e.target;

            setElement(element);
            setCursor(pointer);

            onChange(e.target.value);
        }
    };

    useEffect(() => {
        if (!!cursor && !!element) {
            element.selectionStart = cursor;
            element.selectionEnd = cursor;
        }

    }, [inputValue])




    return (
        <OutlinedInput
            multiline
            notched
            minRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : field.minRows}
            maxRows={field.type === FIELD_TYPES.SHORT_ANSWER ? 1 : 20}
            label={!!field?.label ? renderLabel(field.label, field.tooltip) : null}
            placeholder={field.placeholder}
            disabled={disabled}
            inputProps={{
                maxLength: field.type === FIELD_TYPES.SHORT_ANSWER ? SHORT_ANSWER_MAX_CHARACTERS : LONG_ANSWER_MAX_CHARACTERS,
            }}
            sx={field.type === FIELD_TYPES.SHORT_ANSWER ? {
                '& textarea': {
                    whiteSpace: 'nowrap',
                    resize: 'none',
                    overflowX: 'hidden'
                }
            } : undefined}
            onKeyPress={(e) => {
                if (field.type === FIELD_TYPES.SHORT_ANSWER && e.key === 'Enter') {
                    e.preventDefault();
                }
            }}
            value={inputValue}
            onChange={handleChange}
            onBlur={(e) => { onBlur && onBlur(e.target.value) }}
            onFocus={() => { onFocus && onFocus() }}
        />
    )
}
